import "./lyrics.scss";

export default function Lyrics() {
    return (
        <div className="lyrics">
            <div className="lyrics-wrapper">
                Lyrics
            </div>
        </div>
    )
}
